import React, { useEffect, useState } from 'react';
import 'App.css';
import qs from 'qs';
import { getPreviewTemplate } from '_common/api';
import { get } from 'lodash';
import DOMPurify from 'dompurify';

/**
 * Pull in the query string variables, parse them, fetch any preview templates we can from the data,
 * then show the email on the page
 *
 * @constructor
 */
function App() {
  const defaultText = 'Please wait, translating email...';

  const [previewTemplateData, setPreviewTemplateData] = useState(defaultText);

  // Parsed query string variables
  const { template, itemId, orderId, emailAddress, recoveryToken } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // Parsed language, if not set the default template will be used
  const { language } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  if (template && emailAddress) {
    useEffect(() => {
      try {
        getPreviewTemplate({
          template,
          itemId,
          orderId,
          emailAddress,
          lang: language,
          recoveryToken,
        })
          .then((data) => {
            if (get(data, 'resource')) {
              const unescapedData = unescape(get(data, 'resource'));
              const cleanedData = DOMPurify.sanitize(unescapedData, {
                WHOLE_DOCUMENT: true,
              });
              setPreviewTemplateData(cleanedData);
            }
          })
          .catch((error) => {
            console.log(error);
            setPreviewTemplateData('No translation available, sorry!');
          });
      } catch (e) {
        console.error('There was an error fetching the template', e);
        setPreviewTemplateData('No translation available, sorry!');
      }
    }, []);
  } else {
    useEffect(() => {
      setPreviewTemplateData('No translation available, sorry!');
    }, []);
  }

  return (
    <div className="App">
      <header className="App-header">
        {/* We're setting it dangerously, but also sanitising to the best of our abilities */}
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: previewTemplateData }} />
      </header>
    </div>
  );
}

export default App;
