import axios from 'axios';
import qs from 'qs';
import { Base64 } from 'js-base64';
import env from '@beam-australia/react-env';
import { PreviewParams } from 'types/core';
const apiKey = env('DODDLE_API_KEY') || process.env.REACT_APP_DODDLE_API_KEY || '';
const apiSecret = env('DODDLE_API_SECRET') || process.env.REACT_APP_DODDLE_API_SECRET || '';
const urlBase = env('LEGACY_DODDLE_API_URL') || process.env.REACT_APP_LEGACY_DODDLE_API_URL || '';

/**
 * Get a standard doddle token
 */
const getToken = () => {
  const body = qs.stringify({
    grant_type: 'client_credentials',
    scope: 'email:preview',
  });

  return axios.post(`${urlBase}/v1/oauth/token`, body, {
    headers: {
      Authorization: `Basic ${Base64.encode(`${apiKey}:${apiSecret}`)}`,
    },
  });
};

/**
 * Post the data we have to the preview template API to return to us the given template
 *
 * @param {PreviewParams} params
 */
export const getPreviewTemplate = async (params: PreviewParams) => {
  try {
    const tokens = await getToken();

    return await axios
      .get(`${urlBase}/v1/email/previewtemplate`, {
        params,
        headers: {
          Authorization: `Bearer ${tokens.data.access_token}`,
        },
      })
      .then((res) => res.data);
  } catch (e: any) {
    return Promise.reject(e.response);
  }
};
